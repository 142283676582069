import React, { useContext } from "react";
import "./Details.css";
import { Container } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
const Details = ({ eventDetails }) => {
  const { lang } = useContext(LanguageContext);
  return (
    <div className="eventDetails">
      <Container>
        <h1 className={lang === "ar" ? "EventCard_titleAr" : ""}>
          {eventDetails?.title}
        </h1>
        <img src={eventDetails?.image_path} alt="event content" />
        <div dangerouslySetInnerHTML={{ __html: eventDetails?.description }} />
      </Container>
    </div>
  );
};

export default Details;
