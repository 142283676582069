// eventsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk لجلب بيانات events
export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (lang) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/events`,
      {
        headers: {
          lang: lang,
        },
      }
    );
    return response.data;
  }
);

// Slice
const eventsSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default eventsSlice.reducer;
